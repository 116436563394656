import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "moment/locale/id";
import { id } from "date-fns/locale"; // this is localization for Indonesian Language......
import "../Styles/SelectStation.css";
import { Header, Footer, SelectStationDropDown, Loader } from "../index";
import {
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Paper,
  CircularProgress,
  Typography,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { makeStyles } from "@mui/styles";
import { getSchedule, getStation } from "../../api/ApiUtils";
import { PopUpModal } from "../CommonComponents/PopUp/PopUpModal";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { SelectView, TextView } from "../../common/Components";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import CheckBoxOutlineBlankRoundedIcon from "@mui/icons-material/CheckBoxOutlineBlankRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";

moment.locale("id");

const StationSelection = () => {
  const refreshRef = useRef();
  const classes = useStyle();
  const header = "Cari Jadwal Kereta";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  // STATE'S TO HANDLE THE SLIDERS TO SELECT THE STATION NAMES
  const [startStation, setStartStation] = useState("");
  const [openStartStationDropDown, setOpenStartStationDropDown] =
    useState(false);
  const [stopStation, setStopStation] = useState("");
  const [openStopStationDropDown, setOpenStopStationDropDown] = useState(false);
  const [errorPopUp, showErrorPopUp] = useState({ showPopUp: false });
  const [error, setError] = useState(false);
  const [stationList, setStation] = useState([]);
  // SET THE DEPARTURE DATE
  const [departureDate, setDepartureDate] = useState(new Date());
  // SET CHECKBOX
  const [termsandconditions, setTermsandconditions] = useState(false);
  // STATE TO SELECT PASSENGERS
  const [adultPassengers, setAdultPassengers] = useState(1);
  const [childPassengers, setChildPassengers] = useState(0);
  const [childError, setChildError] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);

  // TO CHECK IF NO OF CHILD PASSENGERS !> ADULT
  useEffect(() => {
    if (childPassengers <= adultPassengers) {
      setChildError(false);
    }
    if (childPassengers > adultPassengers) {
      setChildError(true);
    }
  }, [childPassengers, adultPassengers]);

  // ON SUBMIT BUTTON
  const handleSubmit = async () => {
    if (!termsandconditions) return;
    if (
      startStation &&
      stopStation &&
      startStation !== stopStation &&
      !childError &&
      termsandconditions
    ) {
      const KAI = JSON.parse(localStorage.getItem("KAI"));
      KAI.stationDetails = {
        sourceStation: startStation,
        destnationStation: stopStation,
        date: departureDate,
        adultPassengers: adultPassengers,
        childPassengers: childPassengers,
      };
      localStorage.setItem("KAI", JSON.stringify(KAI)); // store the station details in localStorage

      let req = {
        originStationCode: startStation.stationcode,
        destinationStationCode: stopStation.stationcode,
        departureDate: moment(departureDate).format("YYYY-MM-DD"),
        numberOfAdult: adultPassengers,
        numberOfChildren: childPassengers,
      };
      setLoading(true);
      let res = await getSchedule(req);
      if (res.success) {
        navigate("/stationpricecard");
        setLoading(false);
      } else {
        showErrorPopUp({
          ...errorPopUp,
          showPopUp: true,
          message: res.message,
        });
        setLoading(false);
      }
    } else {
      setLoading(false);
      setError(true);
    }
  };
  // FOR SUBMIT TO ACTIVATE AND DEACTIVATE
  useEffect(() => {
    if (error) {
      if (!childError && startStation && stopStation) {
        setError(false);
      }
    }
  }, [error, childError, startStation, stopStation]);

  useEffect(() => {
    async function getStationCallback() {
      setLoading(true);
      localStorage.removeItem("KAI");
      let KAI = {};
      localStorage.setItem("KAI", JSON.stringify(KAI));
      const response = await getStation();
      if (response.success) {
        setStation(response.data);
        setLoading(false);
      } else {
        showErrorPopUp({
          showPopUp: true,
          message: response.message,
        });
        setLoading(false);
      }
    }

    getStationCallback()
  }, []);

  const handlePopUpClose = () => {
    showErrorPopUp({ ...errorPopUp, showPopUp: false });
  };

  // pull to refresh
  let pullToRefresh = document.querySelector('.pull-to-refresh');
  useEffect(() => {
    let touchstartY = 0;
    function handleRefresh() {
      if (pullToRefresh.classList.contains('visible')) {
        setRefreshLoading(false);
        pullToRefresh.classList.remove('visible');
        window.location.reload();
      }
    }

    function handleTouchStart(e) {
      touchstartY = e.touches[0].clientY;
    }

    function handleTouchMove(e) {
      const touchY = e.touches[0].clientY;
      const touchDiff = touchY - touchstartY;

      if (touchDiff > 0 && window.scrollY === 0) {
        setRefreshLoading(true);
        pullToRefresh.classList.add('visible');
      }
    }

    if (pullToRefresh) {
      // add event listeners 
      refreshRef.current.addEventListener('touchstart', e => {
        handleTouchStart(e)
      });
      refreshRef.current.addEventListener('touchmove', e => {
        handleTouchMove(e)
      });
      refreshRef.current.addEventListener('touchend', e => {
        handleRefresh();
      });
    }
    let refValue = refreshRef.current
    return (() => {
      refValue.removeEventListener('touchstart', handleTouchStart)
      refValue.removeEventListener('touchmove', handleTouchMove)
      refValue.removeEventListener('touchend', handleRefresh)
    })
  }, [pullToRefresh])

  return (
    <div id="StationSelection" className={"StationSelection"}  >
      <div className="pull-to-refresh">
        {refreshLoading && (
          <CircularProgress color="grey" size={20} />
        )}
      </div>
      <Loader loading={loading} />
      <PopUpModal
        open={errorPopUp.showPopUp}
        error={true}
        message={errorPopUp.message}
        handlePopUpClose={handlePopUpClose}
      />

      <div style={{ display: "flex", flexDirection: "column", flex: "1" }} ref={refreshRef}>
        {/* HEADER */}
        <Header header={header} back={""} step={"1"} />

        {/* SCHEDULE STATION CONTAINER */}
        <div id="StationSelectionForm" className={"StationSelectionForm"}>
          {/* Select Dropdown for Station Names Date and no.of Passengers */}
          <div>
            <Typography
              className={"inputLabels"}
              children={"Stasiun Asal"}
              id="StasiunAsal-label"
            />
            <div
              onClick={() =>
                setOpenStartStationDropDown(!openStartStationDropDown)
              }
              className={
                error && !startStation
                  ? `selectStationDropdown selectStationDropdown-error`
                  : "selectStationDropdown"
              }
              id="StasiunAsal-value"
            >
              <Typography
                children={
                  startStation?.stationname || (error && "Pilih Stasiun")
                }
                className={
                  error && !startStation
                    ? `selectInputDataFont selectInputDataFontError`
                    : "selectInputDataFont"
                }
                id="StasiunAsal-name"
              />
              <KeyboardArrowDownRoundedIcon
                className={"iconArrowInSelectInput"}
              />
            </div>
            <span
              style={{ display: error && !startStation ? "" : "none" }}
              className={"errorTextSelectStation"}
            >
              <ErrorRoundedIcon color={"#F75656"} style={{ marginRight: 9 }} />
              Pilih stasiun asal terlebih dahulu
            </span>

            <Typography
              className={"inputLabels"}
              children={"Stasiun Tujuan"}
              id="StasiunTujuan-label"
            />
            <div
              onClick={() =>
                setOpenStopStationDropDown(!openStopStationDropDown)
              }
              className={
                error &&
                  (!stopStation || (stopStation === startStation && startStation))
                  ? `selectStationDropdown selectStationDropdown-error`
                  : "selectStationDropdown"
              }
              id="StasiunTujuan-value"
            >
              <Typography
                children={
                  stopStation?.stationname || (error && "Pilih Stasiun")
                }
                className={
                  (error && !stopStation) ||
                    (error && stopStation === startStation)
                    ? `selectInputDataFont selectInputDataFontError`
                    : "selectInputDataFont"
                }
                id="StasiunTujuan-name"
              />
              <KeyboardArrowDownRoundedIcon
                className={"iconArrowInSelectInput"}
              />
            </div>
            <span
              style={{
                display:
                  (error && !stopStation) ||
                    (error && stopStation === startStation)
                    ? ""
                    : "none",
              }}
              className={"errorTextSelectStation"}
            >
              <ErrorRoundedIcon color={"#F75656"} style={{ marginRight: 9 }} />
              {error && stopStation === ""
                ? "Pilih stasiun tujuan terlebih dahulu"
                : ""}
              {stopStation !== "" && stopStation === startStation
                ? "Nama stasiun tujuan tidak boleh sama dengan Stasiun Asal"
                : ""}
            </span>

            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ flex: "1" }}>
                {" "}
                {/* Train Ticiket Departure Date */}
                <Typography
                  className={"inputLabels"}
                  children={"Tanggal Keberangkatan"}
                  id="TanggalKeberangkatan-label"
                />
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={id}
                >
                  <MobileDatePicker
                    InputProps={{ style: { borderRadius: "8px" } }}
                    disablePast
                    DialogProps={{ className: classes.selectDepartureDate }}
                    value={departureDate}
                    cancelText={""}
                    onChange={(date) => setDepartureDate(date)}
                    renderInput={(params) => {
                      params.inputProps.value = moment(
                        params.inputProps.value,
                        "DD/MM/YYYY"
                      ).format("ddd, DD MMM YYYY");
                      return (
                        <TextView
                          id="stationDate"
                          fullWidth
                          variant={"standard"}
                          className={classes.departureDateTextField}
                          {...params}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <KeyboardArrowDownRoundedIcon
                                  className={"iconArrowInSelectInput"}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      );
                    }}
                  />
                </LocalizationProvider>
              </div>
              {/* Toggle to display Return Ticiket option */}
              {/* <div>
                                <Typography className={"inputLabels"} children={"Pulang Pergi?"} id="PulangPergi-label" />
                                <ThemeSwitch  id="PulangPergi-value" onClick={ () => setReturnTicket(!returnTicket) }  />
                            </div> */}
            </div>

            {/* Adult Passangers */}
            <Typography
              className={"inputLabels"}
              children={"Penumpang Dewasa (>= 3 tahun)"}
              id="PenumpangDewasa-label"
            />
            <SelectView
              id="PenumpangDewasa-value"
              value={adultPassengers}
              onChange={(e) => setAdultPassengers(e.target.value)}
              IconComponent={() => (
                <KeyboardArrowDownRoundedIcon
                  className={"iconArrowInSelectInput"}
                />
              )}
              fullWidth
              variant={"standard"}
            >
              <MenuItem children={"1 Orang"} value={1} />
              <MenuItem children={"2 Orang"} value={2} />
              <MenuItem children={"3 Orang"} value={3} />
              <MenuItem children={"4 Orang"} value={4} />
            </SelectView>
            {/* Infant Passangers */}
            <Typography
              className={"inputLabels"}
              children={"Penumpang Bayi (< 3 tahun)"}
              id="PenumpangBayi-label"
            />
            <SelectView
              id="PenumpangBayi-value"
              error={childError}
              value={childPassengers}
              onChange={(e) => setChildPassengers(e.target.value)}
              IconComponent={() => (
                <KeyboardArrowDownRoundedIcon
                  className={"iconArrowInSelectInput"}
                />
              )}
              fullWidth
              variant={"standard"}
            >
              <MenuItem children={"0 Orang"} value={0} />
              <MenuItem children={"1 Orang"} value={1} />
              <MenuItem children={"2 Orang"} value={2} />
              <MenuItem children={"3 Orang"} value={3} />
              <MenuItem children={"4 Orang"} value={4} />
            </SelectView>
            <span
              style={{ display: childError ? "" : "none" }}
              className={"errorTextSelectStation"}
            >
              <ErrorRoundedIcon color={"#F75656"} style={{ marginRight: 9 }} />
              Penumpang bayi dilarang melebihi jumlah penumpang dewasa
            </span>
          </div>

          <div style={{ margin: "16px auto" }}>
            {/* TERMS & CONDITIONS */}
            <div style={{ margin: "16px 0" }}>
              <Typography className={"terms"}>
                Produk atau jasa ini disediakan oleh Kereta Api Indonesia dan
                sepenuhnya menjadi tanggung jawab Kereta Api Indonesia.
              </Typography>
            </div>

            {/* TREMS & CONDITIONS CHECKBOX */}
            <Paper
              className={`dropShadow`}
              style={{ padding: "16px 0 16px 16px" }}
            >
              <FormControlLabel
                className={classes.checkboxLabel}
                label={
                  <Typography className={"termsCheckbox"} id={'termsandConditions'}>
                    Saya telah membaca dan setuju terhadap{" "}
                    <span>
                      <a
                        className='anchor_links'
                        target="_self"
                        rel="noreferrer"
                        href="https://apps.kereta-api.co.id/termcondition/intercitytrains/ID"
                      >
                        Syarat dan Ketentuan pembelian tiket
                      </a>
                    </span>
                    {" "}
                    dan
                    {" "}
                    <span>
                      <a
                        target="_self"
                        className='anchor_links'
                        rel="noreferrer"
                        href="https://www.ayoconnect.com/privacy-policy"
                      >
                        Kebijakan Privasi
                      </a>
                    </span>
                  </Typography>
                }
                control={
                  <Checkbox
                    style={{ color: "#1F8FE5" }}
                    icon={<CheckBoxOutlineBlankRoundedIcon />}
                    checkedIcon={
                      <CheckBoxRoundedIcon style={{ color: "#1078CA" }} />
                    }
                  />
                }
                onChange={(e) => setTermsandconditions(e.target.checked)}
              />
            </Paper>
          </div>
          {/* SUBMIT BUTTON */}
          <Button
            children={"Cari Kereta Api"}
            fullWidth
            variant={"contained"}
            onClick={() => handleSubmit()}
            id="CariKeretaApi"
            className={
              error || childError || !termsandconditions
                ? `themeButton disableSubmitButton`
                : `themeButton themeButtonContained`
            }
          />
        </div>

        {/* FOOTER */}
        <Footer />
      </div>

      <SelectStationDropDown
        list={stationList}
        slideOpen={openStartStationDropDown}
        setOpenStationDropDown={setOpenStartStationDropDown}
        setStationName={setStartStation}
        title={"Stasiun Awal"}
      />
      <SelectStationDropDown
        list={stationList}
        slideOpen={openStopStationDropDown}
        setOpenStationDropDown={setOpenStopStationDropDown}
        setStationName={setStopStation}
        title={"Stasiun Tujuan"}
      />
    </div>
  );
};

export default StationSelection;

const useStyle = makeStyles({
  selectDepartureDate: {
    // Hide Cancel Button
    "& .MuiDialogActions-root>:first-of-type": {
      display: "none",
    },
    // giv a line after the date displayed at top in Date Picker
    "& .PrivatePickersToolbar-root": {
      borderBottom: "1px solid #DDD !important",
    },
    // removed the edit button which changed the view of the date picker
    "& .MuiPickersToolbar-penIconButton": {
      display: "none",
    },
    // change color and background of the CANCEL and OK button
    "& .MuiButton-root": {
      background: "#1078CA",
      color: "#FFF",
      "&:hover": {
        background: "#00529C",
      },
    },
    // background color applied to date when selected and also when seledted and hover on it
    "& .Mui-selected": {
      backgroundColor: "#1F8FE5 !important",
      "&:hover": {
        backgroundColor: "#00529C !important",
      },
    },
    "& .MuiButtonBase-root": {
      "&:hover": {
        backgroundColor: "#EEF2F6",
      },
    },
  },
  departureDateTextField: {
    // style APPLIED to TEXTFIELD
    "& .MuiOutlinedInput-input": {
      padding: "10px 14px !important",
      fontSize: "14px !important",
    },
  },
});
